@use 'ui/styles/variables' as vars;


.DropdownContainer {
  position: relative;
  z-index: 200;
  max-width: 80vw;
  width: fit-content;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  min-width: 250px;

  &.expanded {
    opacity: 1;
  }

  @include vars.mobileOnly {
    inset: auto 0 0 0 !important;
    transform: translateY(100%) !important;
    transition: all 0.2s ease-in-out;
    width: 100%;
    opacity: 1;
    max-height: calc(100dvh - 2rem);
    max-width: 100vw;

    &.expanded {
      transform: translateY(0%) !important;
    }
  }
}

.FocusContainer {
  height: 100%;
}

.MenuContainer {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000060;
  background-color: var(--ui-divider);
  height: fit-content;
  gap: 1px;
  overflow: hidden;
  width: var(--desktop-width);
  min-width: 250px;

  @include vars.mobileOnly {
    width: 100%;
    max-width: 100%;
  }

  menu {
    margin: 0px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: var(--ui-mutedText);
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
  }
}

.Toggle {
  z-index: auto;
  position: relative;
}

.MobileBackdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #00000060;
  z-index: 100;
  display: block;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;

  &.transition {
    opacity: 1;
  }

  @include vars.mediaQuery('md') {
    display: none;
  }
}

.Header {
  color: var(--ui-mutedText);
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  background-color: #fff;
  justify-content: space-between;

  button {
    @include vars.mediaQuery('md') {
      display: none;
    }
  }
}

.PaginationContainer {
  background-color: #fff;
  height: 32px;

  nav {
    background-color: #fff;
  }
}

.List {
  list-style: none;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: var(--ui-divider);

  li {
    height: 52px;
    background-color: #fff;
    flex-shrink: 0;

    button,
    label {
      height: 100%;
      width: 100%;
      position: relative;

      &.focused {
        input {
          &+.label {
            outline: none;
            background-color: var(--background-lightBlue);
          }
        }
      }

      &:focus-visible {
        outline: 2px solid var(--ui-orange);
        outline-offset: -2px;
        background-color: var(--background-lightBlue);
      }
    }

    .label {
      width: 100%;
      height: 100%;
      padding: 0 16px;
      display: flex;
      gap: 16px;
      justify-content: space-between;
      font-size: 16px;
      align-items: center;
      transition: all 0.2s ease-in-out;
      user-select: none;
      cursor: pointer;

      .countLabel {
        display: flex;
        gap: 8px;
        align-items: center;
      }

      * {
        text-overflow: ellipsis;
      }

      .count {
        color: var(--ui-mutedText);
        font-size: 14px;
      }

      &:hover,
      &.selected {
        background-color: var(--background-lightBlue);
      }

      svg {
        opacity: 0;
        min-width: 24px;
      }
    }

    input:checked+.label {
      background-color: var(--background-lightBlue);
      font-weight: 600;

      svg {
        opacity: 1;
      }
    }

    input {
      opacity: 0;
      position: absolute;
      height: 0;
      width: 0;
      cursor: pointer;

      &:focus-visible+.label {
        outline: none;
        box-shadow: 0 0 0 2px var(--ui-orange);
      }
    }

    .itemChildren {
      flex-direction: column;
      display: flex;
      overflow: hidden;

      span,
      small {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &:after {
        display: block;
        content: attr(data-placeholder);
        font-weight: bold;
        height: 1px;
        color: transparent;
        overflow: hidden;
        visibility: hidden;
      }
    }

    &.hasCount {
      .itemChildren {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        white-space: nowrap;
        min-width: 20px;
        overflow: hidden;

        span,
        small {
          overflow: hidden;
          max-width: 100%;
          white-space: nowrap;
        }

        small {
          font-size: 16px;
          color: var(--ui-mutedText);
          font-weight: 400;
        }
      }



      .count {
        color: var(--ui-mutedText);
        font-weight: 400 !important;
      }
    }
  }
}

.Content {
  padding: 16px 16px;
  background-color: #fff;

  @include vars.mobileOnly {
    max-height: calc(100dvh - 5rem);
    overflow-y: scroll;
    overscroll-behavior: contain;
    scroll-behavior: smooth;
  }
}

.Row {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  background-color: var(--ui-divider);
}

.CheckboxGroup {
  padding: 16px;
  background-color: #fff;
  max-width: 100%;
  flex: 0 0 100%;

  @include vars.mediaQuery('md') {
    flex: 0 0 50%;
    max-width: calc(50% - 0.5px);
  }

  &:only-child {
    max-width: 100%;
    flex: 0 0 100%;
  }

  &.column {
    ul {
      list-style: none;
      flex-direction: column;

      @include vars.mediaQuery('md') {
        flex-direction: row;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
    list-style: none;
  }

  li {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  padding: 8px 16px;
  background-color: #fff;
}

.Pill {
  background-color: #fff;
  border-radius: 100px;
  border: 1px solid var(--ui-divider);
  padding: 7px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: var(--core-ink);
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  user-select: none;

  &:hover:not(:disabled, .disabled),
  &.hover,
  &.hover {
    background-color: var(--background-gray);
    box-shadow: 0 0 0px 1px var(--ui-divider);

    // svg {
    //   transform: rotate(180deg);
    // }
  }

  &:disabled,
  &.disabled {
    background-color: rgba(#b1b1b1, .3);
    border-color: rgba(#b1b1b1, .3);
    color: rgba(#2d2d2d, .3) !important;
  }

  &:focus-visible,
  &.focus {
    outline: none;
    box-shadow: 0 0 0px 2px var(--ui-orange) !important;
  }

  &.expanded {
    background-color: var(--background-gray);
    box-shadow: 0 0 0px 1px var(--ui-divider);

    svg {
      transform: rotate(180deg);
    }
  }

  &.active {
    border-color: var(--ui-link);
    background-color: var(--background-faintBlue);

    &:hover,
    &.hover {
      background-color: var(--background-lightBlue);
      box-shadow: 0 0 0px 1px var(--ui-link);
    }

    &.expanded {
      box-shadow: 0 0 0px 1px var(--ui-link);
    }
  }

  span {
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  svg {
    transition: all 0.2s ease-in-out;
  }

  small {
    font-size: 1em;
    margin: 0px;

    &:before {
      content: '•';
      padding: 0px 4px;
    }
  }
}