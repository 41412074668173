.IntermittentServiceAlert {
  display: flex;
  margin: 0px;
  align-items: center;
}

.AlertIcon {
  height: 36px;
  width: 36px;
  margin-right: 16px;
}

.IntermittentServiceText {
  margin: 0px;
  font-size: 16px;
  padding-top: 5px;
}