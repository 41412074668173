@use 'ui/styles/variables' as vars;

.MenuItem {
  color: var(--ui-link);

  small {
    color: var(--ui-mutedText);
    display: block;
  }

}

.HeaderButtonLink {
  line-height: 40px;
  padding: 0 1em;
  border-radius: 500px;
  color: #fff;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(239, 239, 239, 0.1);
    color: #fff;
    text-decoration: none;
  }

  &:focus {
    color: #fff;
  }

  &:active {
    color: #fff;
  }

}

.DesktopText {
  display: none;

  @include vars.mediaQuery('xl') {
    display: initial;
  }

}

.MemberContainer {
  display: flex;
  user-select: none;
}

.MemberMenuTrigger {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 500px;
  background-color: transparent;
  text-align: left;
  color: var(--core-blue);

  &:hover {
    background-color: rgba(239, 239, 239, 0.1);
    text-decoration: none;
  }

  &:after {
    display: none;
  }

  &:focus-visible {
    outline: 2px solid var(--ui-orange);
  }

}

.Username {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 24px;
  font-size: 1rem;
}

.UserInfo {
  color: var(--core-blue);
  font-weight: bold;
  display: flex;
  align-items: center;

  // &:after {
  //   display: inline-block;
  //   margin-left: .255em;
  //   vertical-align: .255em;
  //   content: "";
  //   border-top: .3em solid;
  //   border-right: .3em solid transparent;
  //   border-bottom: 0;
  //   border-left: .3em solid transparent;
  // }

}

.Subscription {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
}

.SubscriptionType {
  color: #fff;
}

.InfoContainer {
  @include vars.mediaQuery('md') {
    display: flex;
  }

  display: none;
  flex-direction: column;
  padding: 5px 8px;
}