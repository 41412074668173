.Wrapper {
  position: relative;
  width: 100%;
  height: fit-content;
  overflow: hidden;
}

.Map {
  height: 100%;
  width: 100%;

  path {
    stroke: #fff;
    stroke-width: 0.75;
    stroke-miterlimit: 4;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      fill: var(--ui-orange);
      outline: none;
    }
  }

}

.StateMenu {
  max-height: 300px;
  overflow: auto;
}

.Item {
  display: flex;
  align-items: center;
}

.Toggle {
  background-color: var(--background-lighter) !important;
  background: var(--background-lighter) !important;
  color: var(--core-ink) !important;
  border: 1px solid var(--background-gray) !important;
  border-radius: 4px;
  box-shadow: 0px 0px 0px !important;

  &:hover {
    background-color: var(--background-gray);
    background: var(--background-gray);
    color: var(--core-ink);
    border: 1px solid var(--background-gray);
  }

  &:focus {
    background-color: var(--background-gray);
    background: var(--background-gray);
    color: var(--core-ink);
    border: 1px solid var(--background-gray);
  }

  &:active {
    background-color: var(--background-gray);
    background: var(--background-gray);
    color: var(--ui-link) !important;
    border: 1px solid var(--background-gray) !important;
  }

  &:focus-visible {
    background-color: var(--background-gray) !important;
    color: var(--core-ink) !important;
    outline: 2px solid var(--ui-orange);

  }

}

.Dropdowns {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  margin-bottom: -32px;
  padding: 16px;
}