.CheckboxPill {
  position: relative;
  user-select: none;
  overflow: hidden;

  input:checked+.label {
    border: 1px solid var(--ui-link);
    background-color: var(--background-lightBlue);
    width: 100%;

    &:hover {
      background-color: var(--background-faintBlue);
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:focus-visible+.label {
      outline: none;
      box-shadow: 0 0 0 2px var(--ui-orange);
    }
  }

}

.label {
  border-radius: 100px;
  border: 1px solid var(--ui-divider);
  padding: 4px 16px;
  display: flex;
  gap: 4px;
  width: fit-content;
  position: relative;
  font-size: 14px;
  align-items: center;
  text-wrap: nowrap;
  cursor: pointer;
  color: var(--core-ink);

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px var(--ui-orange);
  }

  svg {
    max-height: 1.5em;
    fill: var(--core-slate);
    margin-left: -4px;
  }

  .count {
    color: var(--ui-mutedText);

    &:before {
      content: '•';
      padding: 0px 8px 0px 0px;
    }
  }

  &.selected {
    background-color: var(--background-lightBlue);
    border: 1px solid var(--ui-link);

    &:hover {
      background-color: #fff;
    }
  }
}