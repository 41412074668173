@use 'ui/styles/variables' as vars;

.embedTile {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  font-family: Helvetica;
  min-width: 225px;
  max-width: 600px;
  container-type: inline-size;

  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;

    @include vars.mediaQuery('md') {
      padding: 20px 24px;
    }

    .info {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        color: var(--core-ink);

        @container (width> 400px) {
          font-size: 18px;
        }

        svg {
          margin: 0px 0px 4px 4px;
        }
      }

      .publisher {
        font-size: 14px;
        color: var(--core-ink);
      }
    }
  }

  .image {
    display: flex;
    justify-content: center;
    position: relative;
    border: 1px solid #707070;
    background-color: #A2A2A2;
    max-height: 550px;
    width: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: 10px;

    @container (width < 300px) {
      display: block;
    }

    .tagContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }


    .cta {
      font-size: 14px;
      font-weight: bold;
      color: var(--core-ink);

      a {
        color: var(--ui-link);

        &:hover {
          color: var(--ui-hoverState);
        }
      }
    }
  }
}