@use 'ui/styles/variables' as vars;

.Card {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: var(--ui-divider);
  border: 1px solid var(--ui-divider);
  gap: 1px;
  // overflow: hidden; // overflow is needed for focus state to be visibile (don't add it)

  &.shadow {
    box-shadow: 0px 3px 6px #00000060;
  }

  &.noBorder {
    border: none;
  }

  &.horizontal {
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-template-rows: calc(100% - 32px) 1fr;

    .ThumbnailContainer {
      grid-column-start: 1;
      grid-row-start: 1;
      grid-row-end: 3;
      height: 100%;
      width: 100%;
      border-radius: 4px 0 0 4px;
      display: flex;
      background-color: #fff;
      padding: 8px 8px 8px 0;
      justify-content: center;
      align-items: center;

      .Thumbnail {
        overflow: hidden;
        object-fit: contain;
        object-position: top;
        max-width: calc(100% - 4px);
        height: 100%;
        aspect-ratio: 1/1;
      }
    }

    .Body {
      height: 100%;
      grid-column-start: 2;
      border-radius: 0px 4px 0px 0px;

      &:first-child {
        border-radius: 4px 4px 0px 0px;
        grid-column-start: 1;
        grid-column-end: 3;

        &+.Footer {
          border-radius: 0px 0px 4px 4px;
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }

      &:last-child {
        border-radius: 0px 4px 4px 0px;
        grid-row-start: 1;
        grid-row-end: 3;
      }

      &:only-child {
        border-radius: 4px;
      }

    }

    .Footer {
      border-radius: 0px 0px 4px 0px;
      grid-column-start: 2;
    }
  }

  mark {
    border-radius: 2px;
    outline: 1px solid var(--ui-pe);
    outline-offset: -1px;
    background-color: var(--ui-highlight);
    padding: 0 3px;
    margin: 0 1px 0px 1px;
  }
}

.ThumbnailContainer {
  width: 100%;
  height: auto;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.Thumbnail {
  width: 100%;
  height: auto;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.Title {
  color: var(--core-red);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  display: block;
  text-overflow: ellipsis;
  // overflow: hidden; // overflow is needed for focus state to be visibile
  border-radius: 4px;

  &.loading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2px;
  }

  &:focus-visible {
    outline-offset: 2px;
  }

  &:hover {
    color: var(--alert-dangerDark);
    text-decoration: underline;
  }
}

.Body {
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  padding: 12px 8px 12px 8px;
  font-size: 14px;
  color: var(--ui-mutedText);
  background-color: #fff;

  .CardList {
    display: flex;
    flex-direction: column;
    gap: 1px;
    background-color: var(--ui-divider);

    li {
      background-color: #fff;
      width: 100%;
      padding: 8px;

      &:has(>a) {
        padding: 0px;
      }

      &>a:not([class^="Text"]) {
        position: relative;
        padding: 8px 16px;

        &:focus-visible {
          outline-offset: -2px;
        }

        &:hover {
          background-color: var(--background-faintBlue);
        }
      }

      .Card {
        width: 100%;
        border: none;
      }
    }
  }

  &:first-child {
    border-radius: 4px 4px 0px 0px;
  }

  &:last-child {
    border-radius: 0px 0px 4px 4px;
  }

  &:only-child {
    border-radius: 4px;
  }

  &:has(.CardList) {
    padding: 0px 8px !important;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.Footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
  min-height: 32px;
  height: min-content;
  background-color: #fff;
  width: 100%;
  align-self: self-end;

  &:last-child {
    border-radius: 0px 0px 4px 4px;
  }
}

.Columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  gap: var(--card-gap);
  margin-bottom: var(--margin-bottom);
  align-items: stretch;

  @include vars.mobileOnly() {
    grid-template-columns: repeat(var(--mobileCard-columns), 1fr);
    gap: var(--mobileCard-gap);
  }

  &>.Card {
    width: 100%;
    max-width: calc((100% / var(--card-columns)) - (((var(--card-columns) - 1) / var(--card-columns)) * var(--card-gap)));

    @include vars.mobileOnly() {
      max-width: calc((100% / var(--mobileCard-columns)) - (((var(--mobileCard-columns) - 1) / var(--mobileCard-columns)) * var(--mobileCard-gap)));
    }

    @include vars.tabletOnly() {
      max-width: calc((100% / var(--tabletCard-columns)) - (((var(--tabletCard-columns) - 1) / var(--tabletCard-columns)) * var(--tabletCard-gap)));
    }
  }
}

.Header {
  font-weight: bold;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 8px;

  &.large {
    font-size: 1.5rem;
    font-weight: 500;
    padding: 8px 16px;

    &+.Body {
      padding: 12px 16px;
    }
  }
}