.JavascriptRequired {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #fcf29f;
  border: 1px solid #e2c667;
  padding: 10px 20px;
  width: 100%;
  top: 64px;
  z-index: 1;
}

.JavascriptMessage {
  max-width: 1320px;
}