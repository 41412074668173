.Divide {
  height: 1px;
  background-color: var(--ui-divider);
}

.VerticalDivider {
  width: 1px;
  background-color: var(--ui-divider);
}

.DividerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  .Divider {
    position: absolute;
  }

  .Text {
    background-color: #fff;
    z-index: 2;
    padding: 0 8px;
  }
}

.DividerGrid {
  background-color: var(--ui-divider);
  position: relative;

  .Cell {
    background-color: #fff;
    width: 100%;
    position: relative;

    &.column {
      display: flex;
      flex-direction: column;
      gap: 1px;
      width: 100%;
      background-color: var(--ui-divider);

      &:has(.Cell) {
        >.Cell {
          height: 100%;
        }
      }
    }

    &.row {
      display: flex;
      flex-direction: row;
      gap: 1px;
      background-color: var(--ui-divider);
      height: 100%;
    }
  }
}